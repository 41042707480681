<template>
  <div v-bind:class="{
      'badgeModal': true,
      'badgeModal--unachieved': !badge.earned_at,
    }"
  >
    <img
      v-if="!!badge.earned_at"
      v-bind:src="badge.image"
      v-bind:alt="badge.name"
      class="badgeModal__image"
    />

    <div
      v-if="!badge.earned_at"
      class="badgeModal__imagePlaceholder"
    >
      <font-awesome-icon
        icon="lock"
        class="badgeModal__imagePlaceholderIcon"
      />
    </div>

    <div class="badgeModal__title">
      {{ badge.name }}
    </div>

    <p class="badgeModal__body">
      {{ badge.description }}
    </p>

    <div
      v-if="badge.progress"
      class="badgeModal__progress"
    >
      <progressbar
        v-bind:percentage="progressPercentage"
        v-bind:height="8"
        background-color="#fff"
        color="#ffb92b"
        class="badgeModal__progressBar"
      />

      <div class="badgeModal__progressCount">
        {{ badge.progress.value }}/{{ badge.progress.goal }}
      </div>
    </div>

    <div
      v-if="!!badge.earned_at && shareLink"
      class="badgeModal__share"
    >
      <div class="badgeModal__shareTitle">
        {{ $t('badgeModal.shareTitle') }}
      </div>

      <div class="badgeModal__shareOptions">
        <a
          v-bind:href="`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`"
          v-on:click="onSocialShareClick('Facebook')"
          target="_blank"
          class="badgeModal__shareOption badgeModal__shareOption--facebook"
        >
          <font-awesome-icon
            v-bind:icon="['fab', 'facebook-f']"
            class="badgeModal__shareOptionIcon"
          />
        </a>

        <a
          v-bind:href="`whatsapp://send?text=${$t('badgeModal.shareSocialCopy')} ${shareLink}`"
          v-on:click="onSocialShareClick('WhatsApp')"
          target="_blank"
          class="badgeModal__shareOption badgeModal__shareOption--whatsapp badgeModal__shareOption--mobile"
        >
          <font-awesome-icon
            v-bind:icon="['fab', 'whatsapp']"
            class="badgeModal__shareOptionIcon"
          />
        </a>

        <a
          v-bind:href="`https://api.whatsapp.com/send?text=${$t('badgeModal.shareSocialCopy')} ${shareLink}`"
          v-on:click="onSocialShareClick('whatsapp')"
          target="_blank"
          class="badgeModal__shareOption badgeModal__shareOption--whatsapp badgeModal__shareOption--desktop"
        >
          <font-awesome-icon
            v-bind:icon="['fab', 'whatsapp']"
            class="badgeModal__shareOptionIcon"
          />
        </a>

        <div
          class="badgeModal__shareOption badgeModal__shareOption--link"
          v-on:click="onCopyLinkClick"
        >
          <font-awesome-icon
            icon="link"
            class="badgeModal__shareOptionIcon"
          />

          <div
            v-if="showLinkCopied"
            class="badgeModal__shareOptionNotification"
          >
            {{ $t('badgeModal.shareLinkCopied') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Progressbar from '@/components/Progressbar';
import eventTracker from '@/mixins/eventTracker';

export default {
  mixins: [
    eventTracker,
  ],

  components: {
    Progressbar,
  },

  props: {
    badge: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showLinkCopied: false,
      shareLink: null,
    };
  },

  computed: {
    progressPercentage() {
      const { goal, value } = this.badge.progress;

      return Math.round((value * 100) / goal);
    },
  },

  async mounted() {
    if (!!this.badge.earned_at) {
      this.shareLink = await this.getShareLink(this.badge.id);
    }

    this.trackEvent('Badged viewed', {
      Title: this.badge.name,
      Achieved: !!this.badge.earned_at,
    });
  },

  methods: {
    ...mapActions({
      getShareLink: 'badges/getShareLink',
    }),

    onCopyLinkClick() {
      navigator.clipboard.writeText(this.shareLink)
        .then(this.onCopyLinkSuccess);
    },

    onCopyLinkSuccess() {
      this.showLinkCopied = true;
      this.onSocialShareClick('Link');

      setTimeout(() => {
        this.showLinkCopied = false;
      }, 3000);
    },

    onSocialShareClick(medium) {
      this.trackEvent('Badge shared', {
        Title: this.badge.name,
        Medium: medium,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.badgeModal {
  text-align: center;

  @include desktop {
    margin: -120px 0 0 0;

    &--unachieved {
      margin: -100px 0 0 0;
    }
  }
}

.badgeModal__image,
.badgeModal__imagePlaceholder {
  margin: 0 0 rem(34px) 0;
  width: 132px;
  height: 132px;
  border-radius: 50%;

  @include desktop {
    margin: 0 0 rem(30px) 0;
    width: 140px;
    height: 140px;
  }
}

.badgeModal__imagePlaceholder {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: rgba($color-black, 0.2);
  background: $color-white;

  @include desktop {
    width: rem(120px);
    height: rem(120px);
  }
}

.badgeModal__imagePlaceholderIcon {
  font-size: rem(22px);
}

.badgeModal__title {
  @include title--bold;
  margin: 0 0 rem(16px) 0;
}

.badgeModal__body {
  @include small;
}

.badgeModal__progress {
  position: relative;
  margin: rem(26px) 0 0 0;
}

.badgeModal__progressCount {
  @include label--bold;
  position: absolute;
  top: -6px;
  left: calc(100% + 10px);
  color: $color-yellow;
}

.badgeModal__share {
  margin: rem(44px) 0 0 0;
}

.badgeModal__shareTitle {
  @include tiny--bold;
  margin: 0 0 rem(5px) 0;
  text-transform: uppercase;
}

.badgeModal__shareOptions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.badgeModal__shareOption {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 rem(5px);
  width: 48px;
  height: 48px;
  font-size: 20px;
  border-radius: 50%;
  background: $color-grey;
  cursor: pointer;

  &--mobile {
    @include desktop {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include desktop {
      display: flex;
    }
  }

  &--facebook {
    font-size: 22px;
    background: $color-facebook;
  }

  &--whatsapp {
    font-size: 25px;
    background: $color-whatsapp;
  }
}

.badgeModal__shareOptionIcon {
  color: $color-white;
}

.badgeModal__shareOptionNotification {
  @include note;
  position: absolute;
  bottom: -32px;
  left: 50%;
  padding: rem(3px) rem(16px);
  border-radius: 6px;
  background: $color-grey;
  color: $color-white;
  white-space: nowrap;
  transform: translateX(-50%);
}
</style>